<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>剧目管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="9">
          <el-input
            placeholder="搜索关键字"
            v-model="queryInfo.searchStr"
            clearable
            @clear="getMovieList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getMovieList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加影片</el-button
          >
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="movieList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="电影名称" prop="名称"></el-table-column>
        <el-table-column label="地区" prop="类型"></el-table-column>
        <el-table-column label="片长" prop="总时长"></el-table-column>
        <el-table-column label="上映时间" prop="上映时间"></el-table-column>
        <el-table-column label="状态" prop="状态" :formatter="statusFormat"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template v-slot:default="scope">
            <!-- {{scope.row}} -->
            <el-tooltip
              class="item"
              effect="dark"
              content="查看影片信息"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-aim"
                size="small"
                @click="showMovieInfo(scope.row.ID)"
              ></el-button>
            </el-tooltip>

            <!-- 修改按钮 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="small"
              @click="showEditDialog(scope.row.ID)"
            ></el-button>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              @click="removeMovieById(scope.row.ID)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加电影的对话框 -->
    <el-dialog
      title="添加影片"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="电影名称" prop="名称">
          <el-input v-model="addForm.名称"></el-input>
        </el-form-item>
        <el-form-item label="电影别名" prop="别名">
          <el-input v-model="addForm.别名"></el-input>
        </el-form-item>
        <el-form-item label="导演名称" prop="导演">
          <el-input v-model="addForm.导演"></el-input>
        </el-form-item>
        <el-form-item label="演职员表" prop="主演">
          <el-input v-model="addForm.主演"></el-input>
        </el-form-item>
        <el-form-item label="电影类型" prop="标签">
          <el-input v-model="addForm.标签"></el-input>
        </el-form-item>
        <el-form-item label="制片地区" prop="类型">
          <el-input v-model="addForm.类型"></el-input>
        </el-form-item>
        <!-- <el-form-item label="语言" prop="language">
          <el-input v-model="addForm.language"></el-input>
        </el-form-item> -->
        <el-form-item label="上映日期" prop="上映时间">
          <el-col :span="24">
            <el-date-picker type="date" placeholder="选择日期" v-model="addForm.上映时间" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="片长" prop="总时长">
          <el-input v-model="addForm.总时长"></el-input>
        </el-form-item>
        <el-form-item label="影片简介" prop="简介">
          <el-input type="textarea" v-model="addForm.简介"></el-input>
        </el-form-item>
        <!-- <el-form-item label="豆瓣评分" prop="score">
          <el-input v-model="addForm.score"></el-input>
        </el-form-item> -->
        <el-form-item label="照片" prop="封面照片">
          <el-upload
            action="https://test-pyssp.iat.wang/api/file/upload"
            list-type="picture-card"
            :on-success="uploadSuccess"
            :before-upload="uploadBefore"
            :on-remove="uploadRemove"
            accept="image/*"
            :limit="1"
            :file-list="addForm.封面照片"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-form-item>
      </el-form>
      <!-- 底部按钮区 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMovie()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改电影的对话框 -->
    <el-dialog
      title="修改影片"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="电影名称">
          <el-input v-model="editForm.名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="别名">
          <el-input v-model="editForm.别名"></el-input>
        </el-form-item>
        <el-form-item label="导演名称" prop="导演">
          <el-input v-model="editForm.导演"></el-input>
        </el-form-item>
        <el-form-item label="演职员表" prop="主演">
          <el-input v-model="editForm.主演"></el-input>
        </el-form-item>
        <el-form-item label="电影类型" prop="标签">
          <el-input v-model="editForm.标签"></el-input>
        </el-form-item>
        <el-form-item label="制片地区" prop="类型">
          <el-input v-model="editForm.类型"></el-input>
        </el-form-item>
        <!-- <el-form-item label="语言" prop="language">
          <el-input v-model="editForm.language"></el-input>
        </el-form-item> -->
        <el-form-item label="上映日期" prop="上映时间">
          <el-col :span="24">
            <el-date-picker type="date" placeholder="选择日期" v-model="editForm.上映时间" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="片长" prop="总时长">
          <el-input v-model="editForm.总时长"></el-input>
        </el-form-item>
        <el-form-item label="影片简介" prop="简介">
          <el-input type="textarea" v-model="editForm.简介"></el-input>
        </el-form-item>
        <!-- <el-form-item label="豆瓣评分">
          <el-input v-model="editForm.score"></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 底部按钮区 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeMovie()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看电影详细信息 -->
    <el-dialog title="电影详情页" :visible.sync="showdialogVisible" width="50%">
      <div class="exhibit">
        <div>
          <img :src="showInfo.封面照片" alt="封面照片" width="100%" height="100%"/>
        </div>
        <div>
          <span class="infodes">电影名称：</span>
          <span>{{ showInfo.名称 }}</span>
        </div>
        <div>
          <span class="infodes">导演：</span>
          <span>{{ showInfo.导演 }}</span>
        </div>
        <div>
          <span class="infodes">主演：</span>
          <span>{{ showInfo.主演 }}</span>
        </div>
        <div>
          <span class="infodes">类型：</span>
          <span>{{ showInfo.标签 }}</span>
        </div>
        <div>
          <span class="infodes">制片国家/地区：</span>
          <span>{{ showInfo.类型 }}</span>
        </div>
        <!-- <div>
          <span class="infodes">语言：</span>
          <span>{{ showInfo.language }}</span>
        </div> -->
        <div>
          <span class="infodes">上映时间：</span>
          <span>{{ showInfo.上映时间 }}</span>
        </div>
        <div>
          <span class="infodes">片长：</span>
          <span>{{ showInfo.总时长 }}</span>
        </div>
        <div>
          <span class="infodes">剧情介绍：</span>
          <p>{{ showInfo.简介 }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 父组件向子孙组件传递数据
  // 不论子组件有多深，只要调用了inject那么就可以注入provider中的数据
  // inject: ['loading', 'loaded'],
  name: "play",
  data() {
    return {
      //获取电影列表的参数对象
      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
      },
      movieList: [],
      total: 0,
      //控制添加电影对话框的隐藏和显示
      addDialogVisible: false,
      // 添加用户的表单数据
      addForm: {
        名称: "",
        别名: "",
        导演: "",
        主演: "",
        标签: "",
        类型: "",
        // language: "",
        上映时间: "",
        总时长: "",
        简介: "",
        // score: "",
        封面照片: "",
        path: "",
      },
      // 添加用户的表单的验证规则对象
      addFormRules: {
        名称: [{ required: true, message: "请输入电影名称", trigger: "blur" }],
        别名: [{ required: true, message: "请输入电影别名", trigger: "blur" }],
        导演: [
          { required: true, message: "请输入导演姓名", trigger: "blur" },
        ],
        主演: [{ required: true, message: "请输入主演姓名", trigger: "blur" }],
        标签: [{ required: true, message: "请输入电影类型", trigger: "blur" }],
        类型: [{ required: true, message: "请输入制片地区", trigger: "blur" }],
        // language: [
        //   { required: true, message: "请输入电影语言", trigger: "blur" },
        // ],
        上映时间: [{ required: true, message: '请选择日期', trigger: 'change' }],
        总时长: [
          { required: true, message: "请输入电影时长", trigger: "blur" },
          { type: "number", message: "请输入数字格式", trigger: "blur", transform(value) { return Number(value); } }
        ],
        简介: [
          { required: true, message: "请输入电影简介", trigger: "blur" },
        ],
        // score: [
        //   { required: true, message: "请输入电影豆瓣评分", trigger: "blur" },
        // ],
      },

      // 修改对话框的显示与隐藏
      editDialogVisible: false,
      // 查询到的用户信息对象
      editForm: {},
      //修改表单的验证规则
      editFormRules: {
        导演: [
          { required: true, message: "请输入导演姓名", trigger: "blur" },
        ],
        主演: [{ required: true, message: "请输入主演姓名", trigger: "blur" }],
        标签: [{ required: true, message: "请输入电影类型", trigger: "blur" }],
        类型: [{ required: true, message: "请输入制片地区", trigger: "blur" }],
        // language: [
        //   { required: true, message: "请输入电影语言", trigger: "blur" },
        // ],
        上映时间: [{ required: true, message: '请选择日期', trigger: 'change' }],
        总时长: [
          { required: true, message: "请输入电影时长", trigger: "blur" },
          { type: "number", message: "请输入数字格式", trigger: "blur", transform(value) { return Number(value); } }
        ],
        简介: [
          { required: true, message: "请输入电影简介", trigger: "blur" },
        ],
        // score: [
        //   { required: true, message: "请输入电影豆瓣评分", trigger: "blur" },
        // ],
      },
      // 展示对话框的显示与隐藏
      showdialogVisible: false,
      // 电影展示信息
      showInfo: {},
      //图片
      dialogImageUrl: "",
      dialogVisible: false,
      //图片框是否展示
      isDisplay: false,

    };
  },
  created() {
    this.getMovieList();
  },
  methods: {
    // 获取电影列表
    async getMovieList() {
      const data = await this.$axios.get(
        "/manager/movieList",
        {
          params: this.queryInfo,
        }
      );

      // console.log(data)
      if (data.code == 200) {
        this.movieList = data.data;
        this.total = data.total;
        console.log(this.total);
      } else {
        this.$message.warning(res.msg);
      }
    },
    // 监听页码值改变的
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.currentPage = newPage;
      this.getMovieList();
    },
    //上传头像
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    //监听对话框关闭 清空
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加电影信息
    addMovie() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        // 发起网络请求
        // addDialogVisible = false
        // console.log(this.addForm);
        this.$axios({
          method: "post",
          url: "/manager/addMovie",
          data: this.addForm,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              // 隐藏对话框
              this.addDialogVisible = false;

              //图片相关
              this.addForm.封面照片 = "";
              this.addForm.path = "";
              // 刷新列表
              this.getMovieList();
            } else {
              this.$message.warning(res.msg);
              this.addForm.名称 = "";
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    },
    //监听修改对话框打开
    showEditDialog(id) {
      this.editDialogVisible = true;
      console.log(id);
      // 根据id查询电影信息
      this.$axios({
        method: 'get',
        url: "/manager/movieDetail?id=" + id,
      }).then((res) => {
        // console.log(res.data);
        this.editForm = res.data;
      });
    },
    //监听对话框关闭 清空
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    //修改电影信息并提交
    changeMovie() {
      this.$refs.editFormRef.validate((valid) => {
        // console.log(valid);
        if (!valid) return;
        console.log(this.editForm)
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$axios({
          method: "post",
          url: "/manager/editMovie",
          data: this.editForm,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            loading.close()
            if (res.code == 200) {
              // 隐藏对话框
              this.editDialogVisible = false;
              // 刷新列表
              this.getMovieList();
              //提示修改成功
              this.$message.success("更新电影信息成功");
            } else {
              this.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            loading.close()
            console.log(err.message);
          });
      });
    },
    //根据Id删除电影对话框
    removeMovieById(id) {
      // 弹框
      this.$confirm("此操作将永久删除该电影信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          const data = await this.$axios({
            method: "post",
            url: "/manager/deleteMovie",
            data: {
              id
            },
            headers: {
              "Content-Type": "application/json",
            },
          });

          loading.close()
          if (data.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            // 刷新列表
            this.getMovieList();
          } else {
            this.$message({
              type: "warning",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //展示电影详情
    showMovieInfo(id) {
      // console.log(id)
      this.showdialogVisible = true;
      this.$axios({
        method: "get",
        url: "/manager/movieDetail/?id=" + id,
      })
        .then((res) => {
          // console.log(res.data);
          this.showInfo = res.data;
          this.showInfo.封面照片 = JSON.parse(this.showInfo.封面照片)[0].url
        })
        .catch((err) => console.log(err));
    },
    //图片
    uploadSuccess(res) {
      this.addForm.封面照片 = res.data;
      this.addForm.path = res.data;
      // console.log(this.addForm);
      this.isDisplay = true;
    },
    uploadBefore(file) {
      let limitMax = 5000 * 1024;
      if (file.size > limitMax) {
        this.$message.warning("大小超出限制");
        return false;
      }
    },
    uploadRemove() {
      // this.$axios({
      //   url: "http://localhost:5500/disposts",
      //   params: { filepath: this.addForm.path },
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => err);
      this.addForm.封面照片 = "";
      this.addForm.path = "";
      // console.log(this.addForm);
      this.isDisplay = false;
    },
    statusFormat(row, column) {
      const statusOptions = {
        '1': '正常',
        '2': '预售',
        '3': '停售'
      }
      if (column.property == '状态') {
        return statusOptions[row.状态]
      }
    }
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.hide .el-upload--picture-card {
  display: none;
}
</style>
